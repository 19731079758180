import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Forget from '../views/Forget.vue'
import Member from '../views/Member.vue'
import Category from '../views/Category.vue'
import Post from '../views/Post.vue'
import SiteMap from '../views/SiteMap.vue'
import SisterSchools from '../views/SisterSchools.vue'
import Apply from '../views/Apply.vue'
import ApplyPrint from '../views/ApplyPrint.vue'
import ForeignContact from '../views/ForeignContact.vue'
import ForeignReport from '../views/ForeignReport.vue'
import ExchangeSchool from '../views/ExchangeSchool.vue'
import Search from '../views/Search.vue'
import ApplyPrintForManager from '../views/ApplyPrintForManager.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register
  },
  {
    path: '/Forget',
    name: 'Forget',
    component: Forget
  },
  {
    path: '/Member',
    name: 'Member',
    component: Member
  },
  {
    path: '/Category/:CId',
    name: 'Category',
    component: Category
  },
  {
    path: '/Post/:PId/:CId?',
    name: 'Post',
    component: Post
  },
  {
    path: '/SiteMap',
    name: 'SiteMap',
    component: SiteMap
  },
  {
    path: '/SisterSchools/:Continent?',
    name: 'SisterSchools',
    component: SisterSchools
  },
  {
    path: '/Apply/:ApplyType/:AId?',
    name: 'Apply',
    component: Apply
  },
  {
    path: '/ApplyPrint/:AId',
    name: 'ApplyPrint',
    component: ApplyPrint
  },
  {
    path: '/ForeignContact/:AId',
    name: 'ForeignContact',
    component: ForeignContact
  },
  {
    path: '/ForeignReport/:AId',
    name: 'ForeignReport',
    component: ForeignReport
  },
  {
    path: '/ExchangeSchool',
    name: 'ExchangeSchool',
    component: ExchangeSchool
  },  
  {
    path: '/Search/:Keyword',
    name: 'Search',
    component: Search
  },
  {
    path: '/ApplyPrintForManager/:AId',
    name: 'ApplyPrintForManager',
    component: ApplyPrintForManager
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
