<template>
  <div>
    <Head />

    <nav class="breadcrumb_box" v-if="CategoryData">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName }}</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper list_1" v-if="CategoryData && CategoryData.Category.Type == 0">
      <!-- 最新消息 -->
      <section class="news">
        <div class="bg">
          <div class="container">
            <div class="title_box">
              <img src="img/title_icon1.svg" alt="">
              <h2>{{ CategoryData.Category.CategoryName }}</h2>
            </div>
            <ul class="list show">
              <li v-for="Post in Posts" :key="Post.PId">
                <a href="javascript:void(0)" @click="gotoPost(Post)">
                  <div class="tag_box">
                    <div>{{ CategoryData.Category.CategoryName }}</div>
                    <img src="img/news_hot.svg" alt="" v-if="Post.IsTop">
                  </div>
                  <div class="text_box">
                    <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                    <p>{{ Post.Title }}</p>
                  </div>
                </a>
              </li>
            </ul>
            <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
              :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
              :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
              :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
            </paginate>
          </div>
        </div>
      </section>
    </div>

    <div class="wrapper list_1 list_2" v-if="CategoryData && CategoryData.Category.Type == 1">
      <!-- 最新消息 -->
      <section class="news">
        <div class="bg">
          <div class="container">
            <div class="title_box">
              <img src="img/title_icon1.svg" alt="">
              <h2>{{ CategoryData.Category.CategoryName }}</h2>
            </div>
            <ul class="list show">
              <li v-for="Post in Posts" :key="Post.PId">
                <a href="javascript:void(0)" @click="gotoPost(Post)">
                  <div class="tag_box">
                    <div>{{ CategoryData.Category.CategoryName }}</div>
                    <img src="img/news_hot.svg" alt="" v-if="Post.IsTop">
                  </div>
                  <div class="box">
                    <div class="img_box">
                      <div class="img" style="z-index: 1;">
                        <img :src="CategoryData.SiteUrl + Post.Image" alt="" v-if="Post.Image" />
                        <img src="img/default.png" alt="" v-if="!Post.Image">
                      </div>
                    </div>
                    <div class="text_box">
                      <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                      <p>{{ Post.Title }}</p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
              :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
              :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
              :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
            </paginate>
          </div>
        </div>
      </section>
    </div>

    <div class="wrapper list_1 faqs" v-if="CategoryData && CategoryData.Category.Type == 2">
      <section class="container">
        <div class="">
          <div class="title_box">
            <img src="img/title_icon_qa.svg" alt="">
            <h2>常見問題 FAQS</h2>
          </div>
          <!-- <div class="btn_box">
            <button class="active">
              <p>全部類型</p>
            </button>
            <button>
              <p>交換學生計畫</p>
            </button>
            <button>
              <p>教育部學海計畫</p>
            </button>
            <button>
              <p>境外來校交換生入學</p>
            </button>
          </div> -->
          <ul class="list show">
            <li v-for="Post in Posts" :key="Post.PId">
              <div class="question" style="cursor: pointer;" @click="QAIndex == Post.PId ? QAIndex = -1 : QAIndex = Post.PId">
                <img src="img/faqs_q.svg" alt="">
                <p>{{ Post.Title }}</p>
              </div>
              <div class="answer" :style="QAIndex == Post.PId ? 'display: block;' : ''">
                <hr>
                <div class="ans">
                  <img src="img/faqs_a.svg" alt="">
                  <div v-html="Post.Content">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
          :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
          :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
          :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
        </paginate>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Category",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      CategoryData: null,
      Posts: null,
      local: "zh_tw",
      pageAll: 0,
      perPage: 5,
      QAIndex: -1,
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Category",
        {
          params: { CId: to.params.CId, lang: $cookies.get("is_site_lang") },
        }
      )
      .then((response) => {
        this.CategoryData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Category",
        {
          params: { CId: this.$route.params.CId, lang: $cookies.get("is_site_lang") },
        }
      )
      .then((response) => {
        this.CategoryData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/PostList",
        type: "post",
        data: {
          CId: this.$route.params.CId,
          page: page,
          perPage: that.perPage,
          lang: $cookies.get("is_site_lang"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            this.QAIndex = -1;
            that.Posts = res.Posts;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId, CId: this.CategoryData.Category.CId },
        });
      }
    },
  },
};
</script>

